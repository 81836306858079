<template>
  <VList
    :color="color"
    :density="density"
    :max-height="maxHeight"
    :nav="nav"
    :select-strategy="selectStrategy"
    :value="value"
    @input="emit('input', $event)"
  >
    <slot />
  </VList>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    color?: string
    density?: 'default' | 'compact'
    nav?: boolean
    maxHeight?: string | number
    value?: any // TODO: VListのドキュメントにはないが、動いてそう
    selectStrategy?: 'single-leaf' | 'leaf'
  }>(),
  {
    color: undefined,
    density: 'default',
    nav: false,
    maxHeight: undefined,
    value: undefined,
    selectStrategy: 'single-leaf',
  }
)

const emit = defineEmits<{
  input: [value: any] // TODO: VListのドキュメントにはないが、動いてそう
}>()
</script>

<style scoped></style>
